import React, { useState } from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { CSSTransition } from "react-transition-group"

import { starterPercentage } from "../utils/constants"
import { signupsDisabled } from "../utils/config"
import AppLink from "./AppLink"

const StickyBar = () => {
  const [showBar, setShowBar] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      // prevPos
      const shouldShowBar = currPos.y < -280

      if (showBar === shouldShowBar) return

      setShowBar(shouldShowBar)
    },
    [showBar]
  )

  return (
    <CSSTransition
      in={showBar}
      classNames="fade"
      timeout={300}
      unmountOnExit={true}
    >
      <div className="fixed z-10 w-full bottom-0 left-0 px-4 py-3 sticky-pricing-bar flex items-center md:justify-center">
        {/* <a
          href={calendarURL}
          target="_blank"
          className="border-payhere border-2 whitespace-nowrap text-payhere text-lg font-semibold font-display px-3 py-2 rounded-md mr-2 hidden sm:block"
        >
          Book a demo
        </a> */}
        {!signupsDisabled && (
          <AppLink
            path="/signups/new"
            params={{ plan: "starter" }}
            className="bg-payhere border-payhere border-2 whitespace-nowrap text-white text-lg font-semibold font-display px-3 py-2 rounded-md"
          >
            Try it now
          </AppLink>
        )}
        <div className="hidden md:block text-xs md:text-base ml-3 md:ml-5">
          Starting at {starterPercentage} per transaction plus Stripe fees.
          <span className="hidden md:block text-xs">
            No credit card required.
          </span>
        </div>
      </div>
    </CSSTransition>
  )
}

export default StickyBar
