import React from "react"
import { Link } from "gatsby"

const NavLink = ({ exact, ...props }) => (
  <Link
    {...props}
    getProps={({ isPartiallyCurrent, isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      const matches = exact ? isCurrent : isPartiallyCurrent
      return {
        className: `${props.className}${matches ? ` text-gray-900` : ``}`,
      }
    }}
  />
)

export default NavLink
