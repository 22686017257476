import React, { useEffect } from "react"
import Helmet from "react-helmet"
import Happi from "happi-widget"
import "happi-widget/dist/index.css"

import "../../styles/screen.css"
import Footer from "./Footer"
import StoreUTMParams from "../StoreUTMParams"
import TopNav from "./TopNav"
import StickyBar from "../StickyBar"

const LayoutBase = ({ children }) => {
  useEffect(() => {
    Happi.init({
      publishableKey: process.env.HAPPI_PUBLISHABLE_KEY,
      categoryHeader: "Category",
      categories: ["Technical support", "Pricing enquiry", "Other"],
      iconBg: "#ff8a4c",
      iconTextColor: "white",
      iconText: "Have questions?",
      subheading: "Our team usually reponds within 1-2 working days.",
      launchSelector: ".launch-messenger",
      // debug: true,
      styles: {
        headerBgColor: "#081E41",
        headerTextColor: "white",
        buttonBgColor: "#1c64f2",
        buttonTextColor: "#fff",
      },
    })
  }, [])

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <StoreUTMParams />
      <TopNav />
      <main id="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <Footer />
      <StickyBar />
    </>
  )
}

export default LayoutBase
