import qs from "qs"

const API_URL = process.env.GATSBY_API_URL

function getUTMParams(location, log) {
  try {
    const utm_params = {}

    // Try from URL
    const URLparams = qs.parse(location.search, { ignoreQueryPrefix: true })

    if (URLparams.affiliate && URLparams.affiliate.length > 0) {
      utm_params.affiliate = URLparams.affiliate
    }
    if (URLparams.utm_source && URLparams.utm_source.length > 0) {
      utm_params.utm_source = URLparams.utm_source
    }
    if (URLparams.utm_medium && URLparams.utm_medium.length > 0) {
      utm_params.utm_medium = URLparams.utm_medium
    }
    if (URLparams.utm_campaign && URLparams.utm_campaign.length > 0) {
      utm_params.utm_campaign = URLparams.utm_campaign
    }

    // Try from localstorage, overwrite if present
    const affiliate_code =
      typeof localStorage !== "undefined" &&
      localStorage.getItem("payhere:affiliate")
    const utm_source =
      typeof localStorage !== "undefined" &&
      localStorage.getItem("payhere:utm_source")
    const utm_medium =
      typeof localStorage !== "undefined" &&
      localStorage.getItem("payhere:utm_medium")
    const utm_campaign =
      typeof localStorage !== "undefined" &&
      localStorage.getItem("payhere:utm_campaign")

    if (affiliate_code && affiliate_code.length > 0)
      utm_params.affiliate = affiliate_code
    if (utm_source && utm_source.length > 0) utm_params.utm_source = utm_source
    if (utm_medium && utm_medium.length > 0) utm_params.utm_medium = utm_medium
    if (utm_campaign && utm_campaign.length > 0)
      utm_params.utm_campaign = utm_campaign

    if (log) {
      console.log({ utm_params })
    }
    return utm_params
  } catch (err) {
    console.log("Error parsing UTM params", err.message)
    return {}
  }
}

export const root = "/"
export const courses = "/courses/"
export const clubs = "/clubs-and-charities/"
export const servicesBusinesses = "/services-businesses/"
export const features = "/features/"
export const pricing = "/pricing/"
export const websiteBuilder = "/storefront/"
export const invoicing = "/invoicing/"
export const paymentLinks = "/payment-links/"
export const donations = "/donations/"
export const blog = "/guides/"
export const contact = "/contact/"
export const terms = "/terms/"
export const privacy = "/privacy/"
export const security = "/security/"
export const integrations = "/integrations/"
export const resolve = doc => {
  switch (doc._type) {
    case "page":
      return `/${doc.slug.current}/`
    default:
      return `/${doc.slug.current}/`
  }
}
export const buildAppLink = (location, path, queryParams = {}, log = false) => {
  const query = qs.stringify(
    Object.assign({}, queryParams, getUTMParams(location, log))
  )

  const url = `${API_URL}${path}${query.length ? `?${query}` : ""}`
  // console.log("Building plan URL", query, url)

  return url
}
