import { isString } from "lodash"
import React from "react"
import { Location } from "@reach/router"
import qs from "qs"

const StoreUTMParams = () => {
  return (
    <Location>
      {({ location: { search } }) => {
        try {
          const params = qs.parse(search, { ignoreQueryPrefix: true })
          if (isString(params.affiliate) && params.affiliate.length > 0) {
            localStorage.setItem("payhere:affiliate", params.affiliate)
          }
          if (isString(params.utm_source) && params.utm_source.length > 0) {
            localStorage.setItem("payhere:utm_source", params.utm_source)
          }
          if (isString(params.utm_medium) && params.utm_medium.length > 0) {
            localStorage.setItem("payhere:utm_medium", params.utm_medium)
          }
          if (isString(params.utm_campaign) && params.utm_campaign.length > 0) {
            localStorage.setItem("payhere:utm_campaign", params.utm_campaign)
          }
        } catch (err) {
          console.log("Error tracking", err)
        }
        return null
      }}
    </Location>
  )
}

export default StoreUTMParams
