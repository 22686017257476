import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { buildAppLink } from "../utils/routes"

const AppLink = ({ path, params, children, ...props }) => {
  const location = useLocation()
  const [link, setLink] = useState(null)

  useEffect(() => {
    const updatedLink = buildAppLink(location, path, params)
    setLink(updatedLink)
  }, [path, params, location])

  return link ? (
    <a href={link} {...props}>
      {children}
    </a>
  ) : (
    <a href="#" {...props}>
      {children}
    </a>
  )
}

export default AppLink
