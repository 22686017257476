import React from "react"

const Divider = ({ borderColor, className }) => {
  return (
    <hr
      className={`${
        borderColor ? borderColor : `border-alpha-06`
      } ${className}`}
    />
  )
}

export default Divider
