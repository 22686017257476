import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

import Divider from "../Divider"
import AppLink from "../AppLink"
import * as routes from "../../utils/routes"

const pagesQuery = graphql`
  query LayoutFooterPagesQuery {
    allSanityPage(filter: { showInNav: { eq: true } }) {
      edges {
        node {
          id
          slug {
            current
          }
          name
          heading
        }
      }
    }
  }
`

const Footer = () => {
  return (
    <StaticQuery
      query={pagesQuery}
      render={data => (
        <div className="bg-gray-100">
          <footer className="max-w-5xl mx-auto p-6 sm:pt-10 md:pt-12 pb-28">
            <svg
              viewBox="0 0 51 60"
              className="h-12 md:h-14 text-gray-300 mb-5"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                  d="M1.36243,0.00415039 L16.7432,0.00415039 C17.3062,0.00415039 17.7866,0.325991 18.0025,0.845816 C18.2173,1.36564 18.1047,1.93238 17.7069,2.33023 L2.32608,17.711 C1.92823,18.1089 1.36149,18.2215 0.840728,18.0066 C0.320903,17.7908 0,17.3104 0,16.7474 L0,1.36658 C0,0.616868 0.611779,0.00415039 1.36243,0.00415039 Z M25.6028,0.00151 C28.6382,-0.00411987 31.2111,-0.0341459 34.2737,0.838484 C43.502,3.46857 50.3104,11.9988 50.3104,22.0415 C50.3104,34.1636 40.3925,44.0806 28.2713,44.0806 L12.4486,44.0806 L22.0391,47.2239 L22.0391,58.6376 C22.0391,59.3882 21.4264,60 20.6766,60 L1.36243,60 C0.611779,60 0,59.3882 0,58.6376 L0,25.608 C0,25.2327 0.134179,24.9099 0.398783,24.6444 L24.6429,0.401231 C24.9066,0.136627 25.2284,0.00244831 25.6028,0.00151 Z"
                  id="Shape"
                  fill="currentColor"
                ></path>
              </g>
            </svg>
            <ul className="flex flex-col sm:flex-row">
              <li className="mb-2 sm:mb-0 sm:mr-6">
                <Link
                  to={routes.pricing}
                  className="no-underline text-lg font-semibold text-gray-500"
                >
                  Pricing
                </Link>
              </li>
              <li className="mb-2 sm:mb-0 sm:mr-6">
                <a
                  href="https://developers.payhere.co/docs/intro.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline text-lg font-semibold text-gray-500"
                >
                  Developers
                </a>
              </li>
              <li className="mb-2 sm:mb-0 sm:mr-6">
                <Link
                  to={routes.blog}
                  className="no-underline text-lg font-semibold text-gray-500"
                >
                  Blog
                </Link>
              </li>
              <li className="mb-2 sm:mb-0 sm:mr-6">
                <AppLink
                  path="/merchants"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline text-lg font-semibold text-gray-500"
                >
                  Merchant Login
                </AppLink>
              </li>
              <li>
                <AppLink
                  path="/customers"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="no-underline text-lg font-semibold text-gray-500"
                >
                  Customer Login
                </AppLink>
              </li>
            </ul>
            <Divider className="my-4 sm:my-6" borderColor="border-gray-200" />
            <ul className="flex flex-col sm:flex-row">
              {data.allSanityPage.edges.map(({ node }) => (
                <li key={node.id} className="mb-2 sm:mb-0 sm:mr-6">
                  <Link
                    to={routes.resolve(node)}
                    className="no-underline font-semibold text-sm text-gray-400"
                  >
                    {node.name}
                  </Link>
                </li>
              ))}
              <li className="mt-4 sm:mt-0 sm:flex-1 sm:text-right text-sm text-gray-400">
                &copy; {new Date().getFullYear()} Alternate Labs Ltd T/A Payhere
                {/* <br />
                <a
                  href="https://alternatelabs.co"
                  target="_blank"
                  rel="noopener"
                  className="text-gray-500 text-xs hover:text-gray-900"
                >
                  Made by <span className="font-medium">Alt Labs</span>
                </a> */}
              </li>
            </ul>
          </footer>
        </div>
      )}
    />
  )
}

export default Footer
